import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useOne,
    useMany,
} from "@refinedev/core";
import { Show, TagField, TextField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const ReferidoShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show canDelete isLoading={isLoading}>
            <Title level={5}></Title>
            <Title level={5}>Abrev.</Title>
            <TextField value={record?.abrev} />
            <Title level={5}>Nombre</Title>
            <TextField value={record?.nombre} />
            <Title level={5}>Referidos</Title>
            <>
                {record?.referidos.map((item: string, index: number) => (
                    <TagField key={index} value={item} />
                ))}
            </>
        </Show>
    );
};
