import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    TagField,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";
import { Link } from "react-router-dom";

export const ReferidoList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    title="Acciones"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                             <ShowButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                        </Space>
                    )}
                />
                <Table.Column dataIndex="abrev" title="Abrev." />
                <Table.Column dataIndex="nombre" title="Nombre" />
                <Table.Column
                    dataIndex="referidos"
                    title="Referidos"
                    render={(value: any[]) =>
                        <>
                            {value?.map((item, index) => (
                                <TagField key={index} value={item} />
                            ))}
                        </>
                    }
                />
                 <Table.Column dataIndex={["abrev"]} title="Enlace" render={(
                    value: string,
                    record: BaseRecord,
                ) => (
                    <Link to={"https://app.certinae.es/"+value+"/solicitud/"}>{value}</Link>

                )}/>
            </Table>
        </List>
    );
};
