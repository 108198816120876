import { AuthBindings, Refine, ResourceProps } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { notificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import routerBindings, { UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import dataProvider, { axiosInstance } from "@refinedev/simple-rest";
import { BrowserRouter, redirect } from "react-router-dom";
import { ColorModeContextProvider } from "./contexts/color-mode";
import AppRoutes from "routes";
import { Header } from "components/header";
import { NuevoCertificadoIcon } from "components/icons";
import axios from "axios";
import Icon from "@ant-design/icons/lib/components/Icon";
import { DatabaseOutlined, EuroCircleOutlined, KeyOutlined, MailFilled, MailOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";

function App() {
  const [resourcesPanel, setResourcesPanel] = React.useState<ResourceProps[]>([])
  const authProvider: AuthBindings = {
    login: async ({ email, password }) => {
      let success = false
      if (email) {
        await axios.get(process.env.REACT_APP_API_URL + `/check`, {
          auth: {
            username: email,
            password: password
          }
        })
          .then((response) => {
            localStorage.setItem("email", email);
            localStorage.setItem("password", password)
            success = true
          })
      }
      if (success) {
        return {
          success: true,
          redirectTo: "/",
        };
      } else {
        return {
          success: false,
          error: {
            message: "Login failed",
            name: "Invalid email or password",
          },
        }
      };
    },
    logout: async () => {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      return {
        success: true,
        redirectTo: "/",
      };
    },
    onError: async (error: any) => {
      console.error(error);
      return { error };
    },
    check: async () => {
      return localStorage.getItem("email")
        ? { authenticated: true }
        : {
          authenticated: false,
          redirectTo: "/",
          error: {
            message: "Check failed",
            name: "Not authenticated",
          },
        };
    },
    getPermissions: async () => ["admin"],
    getIdentity: async () => ({
      id: 1,
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
    }),
  };

  let defaultResourcePanel = [
    {
      name: "CEE",
      meta: {
        label: "CEE",
        icon: <NuevoCertificadoIcon />,
      },

    }, {
      name: "NuevoCertificado",
      list: "/",
      meta: {
        label: "Nuevo",
        parent: "CEE",
        icon: <NuevoCertificadoIcon />,
      },

    }, {
      name: "emailCert",
      list: "/enviaremail",
      meta: {
        label: "Enviar",
        parent: "CEE",
        icon: <MailOutlined />,
      },
      
    },{
      name: "S. Ocupación",
      meta: {
        label: "S. Ocupación",
        parent: "",
        icon: <KeyOutlined />,
      },
    },
    {
      name: "segundaOcupacionnuevo",
      list: "/segundaocupacion",
      meta: {
        label: "Nuevo",
        parent: "S. Ocupación",
        icon: <NuevoCertificadoIcon />,
      },
    }, {
      name: "enviarSegundaOcupacion",
      list: "/enviarsegundaocupacion",
      meta: {
        label: "Enviar",
        parent: "S. Ocupación",
        icon: <MailOutlined />,
      },
      //boilerplate
    },
    {
      name: "S. Estructural",
      meta: {
        label: "S. Estructural",
        parent: "",
        icon: <KeyOutlined />,
      },
    },
    {
      name: "seguridadestructuralnuevo",
      list: "/seguridadestructural",
      meta: {
        label: "Nuevo",
        parent: "S. Estructural",
        icon: <NuevoCertificadoIcon />,
      },
    }, {
      name: "enviarseguridadestructural",
      list: "/enviarseguridadestructural",
      meta: {
        label: "Enviar",
        parent: "S. Estructural",
        icon: <MailOutlined />,
      },
      //boilerplate
    },{
      name: "",
      list: "",
      show: "",
      create: "",
      deleteOne: "",
      edit: "",
      meta: {
        label: "",
        parent: "",
        icon: <Icon />,
      },
    }]

  useEffect(() => {
    //esAdmin()
    metodosExtra()
  }, []);

  const esAdmin = () => {
    axios.get(process.env.REACT_APP_API_URL + `/admin`, {
      auth: {
        username: localStorage.getItem("email") || "",
        password: localStorage.getItem("password") || ""
      }
    })
    .then((response) => {
      metodosExtra()
    }).catch((err) => {
      setResourcesPanel(defaultResourcePanel)
    })
  }

  const metodosExtra = () => {
    let panel = defaultResourcePanel
      panel.push(
        {
          name: "Facturación",
          list: "",
          meta: {
            label: "Facturación",
            parent: "",
            icon: <EuroCircleOutlined />
          },
        }, {
        name: "Facturación",
        list: "/generarFacturas",
        meta: {
          label: "Generar",
          parent: "Facturación",
          icon:  <NuevoCertificadoIcon />
        },
      })
      panel.push({
        name: "emailFactura",
        list: "/enviarfactura",
        meta: {
          label: "Enviar",
          parent: "Facturación",
          icon: <MailOutlined />
        },
      })

      panel.push({
        name: "Base de datos",
        list: "",
        meta: {
          label: "Base de datos",
          parent: "",
          icon: <DatabaseOutlined />
        },
      })
      panel.push({
        name: "facturas",
        list: "/facturas",
        show: "/facturas/show/:id",
        create: "/facturas/create",
        deleteOne: "/facturas/delete/:id",
        edit: "/facturas/edit/:id",
        meta: {
          label: "Registros",
          parent: "Base de datos",
          icon: <DatabaseOutlined />
        },
      })
      panel.push({
        name: "clientes",
        list: "/clientes",
        show: "/clientes/show/:id",
        create: "/clientes/create",
        deleteOne: "/clientes/delete/:id",
        edit: "/clientes/edit/:id",
        meta: {
          label: "Clientes",
          parent: "Base de datos",
          icon: <DatabaseOutlined />
        },
      })
      panel.push({
        name: "referidos",
        list: "/referidos",
        show: "/referidos/show/:id",
        create: "/referidos/create",
        deleteOne: "/referidos/delete/:id",
        edit: "/referidos/edit/:id",
        meta: {
          label: "Referidos",
          parent: "Base de datos",
          icon: <DatabaseOutlined />
        },
      })
      // panel.push({
      //   name: "ingresos",
      //   list: "/ingresos",
      //   show: "",
      //   create: "",
      //   deleteOne: "",
      //   edit: "",
      //   meta: {
      //     label: "Ingresos",
      //     parent: "Facturación",
      //     icon: <EuroCircleOutlined />
      //   },
      // })
      setResourcesPanel(panel)
  }

  let cliente = axiosInstance
  cliente.defaults.headers.common['Authorization'] = 'Basic ' + btoa(localStorage.getItem("email") + ":" + localStorage.getItem("password"))

  return (
    <BrowserRouter>

      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            dataProvider={dataProvider(process.env.REACT_APP_API_URL + "/cosmosdb", cliente)}
            authProvider={authProvider}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
            resources={resourcesPanel}>
            <AppRoutes />
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
